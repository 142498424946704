import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  FormControl,
  FormLabel,
  Select,
  StatLabel,
  SimpleGrid,
  Stat,
} from "@chakra-ui/react";
import bgWeather from "assets/img/BgMusicCard.png";
import bgWeatherDark from "assets/img/bgMusicCardDark.png";
import smartHome from "assets/img/smart-home.png";
import sunBehindCloud from "assets/img/sun-behind-cloud.png";
import Card from "components/Card/Card";
import BarChart from "components/Charts/BarChart";
import { HSeparator } from "components/Separator/Separator";
import VisxPieChart from "components/VisxPieChart/VisxPieChart";
import CircularSlider from "react-circular-slider-svg";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiWater, BiWifi } from "react-icons/bi";
import { BsFillRecordCircleFill, BsThermometerHigh } from "react-icons/bs";
import { FaPlus, FaSnowflake } from "react-icons/fa";
import { IoBulbOutline, IoEllipsisVerticalSharp } from "react-icons/io5";
import {
  barChartDataDashboardPage,
  barChartOptionsDashboardPage,
} from "variables/charts";
import { rooms } from "variables/general";
import Tooltip from "react-simple-tooltip";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

// import FluxoFormacaoTab1 from './components/FluxoFormacao/Tab1';
import {
  GET_INEP_GERAL_COLLECTION,
  GET_INEP_REGIOES,
  GET_INEP_UFS,
  GET_INEP_MUNICIPIOS,
} from "queries";
import InepTab2 from "./components/Inep/Tab2";
import InepTab3 from "./components/Inep/Tab3";

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.nescon.medicina.ufmg.br",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const years = [
  2023,
  2022,
  2021,
  2020,
  2019,
  2018,
  2017,
  2016,
  2015,
  2014,
  2013,
  2012,
  2011,
  2010,
];

const formatNumber = (number) => {
  return new Intl.NumberFormat("pt-BR").format(number);
};

const DashboardPage = () => {
  const [activeTab, setActiveTab] = useState("1");
  const bgButtonGroup = useColorModeValue("gray.200", "navy.700");
  const bgActiveButton = useColorModeValue("#fff", "navy.800");
  const [year, setYear] = useState(2023);
  const [inepGeralCollection, setInepGeralCollection] = useState([]);

  // setTpRede foi trocado por setTpCategoriaAdministrativa, mas é mantido caso precise aplicar os dois juntos no futuro
  const [tpRede, setTpRede] = useState(0);
  const [tpCategoriaAdministrativa, setTpCategoriaAdministrativa] = useState(
    "total"
  );

  const [tpModalidadeEnsino, setTpModalidadeEnsino] = useState(0);

  const [inepRegioes, setInepRegioes] = useState([]);
  const [inepRegiao, setInepRegiao] = useState(0);

  const [inepUfs, setInepUfs] = useState([]);
  const [inepUf, setInepUf] = useState(0);

  const [inepMunicipios, setInepMunicipios] = useState([]);
  const [inepMunicipio, setInepMunicipio] = useState(0);

  useEffect(async () => {
    let r = document.querySelector(":root");
    r.style.setProperty("--chakra-colors-blue-500", "#285d7d");

    let getInepRegioesStart = (
      await clientApi.query({
        query: GET_INEP_REGIOES,
      })
    ).data.getInepRegioes;
    setInepRegioes(getInepRegioesStart);

    setInepGeralCollection([]);
    let getInepGeralCollectionStart = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: year,
          tp_rede: parseInt(tpRede),
          tp_categoria_administrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    setInepGeralCollection(getInepGeralCollectionStart);
  }, [
    year,
    tpRede,
    tpCategoriaAdministrativa,
    tpModalidadeEnsino,
    inepRegiao,
    inepUf,
    inepMunicipio,
  ]);

  const _setInepRegiao = async (cod) => {
    setInepRegiao(cod);
    setInepUfs([]);
    let getInepUfsStart = (
      await clientApi.query({
        query: GET_INEP_UFS,
        variables: {
          cod_regiao: cod,
        },
      })
    ).data.getInepUfs;
    console.log("getInepUfsStart", getInepUfsStart);
    setInepUfs(getInepUfsStart);
  };

  const _setInepUf = async (cod) => {
    setInepUf(cod);
    setInepMunicipios([]);
    let getInepMunicipiosStart = (
      await clientApi.query({
        query: GET_INEP_MUNICIPIOS,
        variables: {
          cod_uf: cod,
        },
      })
    ).data.getInepMunicipios;
    console.log("getInepUfsStart", getInepMunicipiosStart);
    setInepMunicipios(getInepMunicipiosStart);
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "0px" }}>
      <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px">
        <Card>
          <Card>
            <Flex direction="column">
              <SimpleGrid columns={{ sm: 1, xl: 1 }}>
                <Stat>
                  <StatLabel
                    fontSize="24"
                    color="#285D7D"
                    fontWeight="bold"
                    textTransform="uppercase"
                    textAlign="center"
                  >
                    Fluxos da Formação dos Cursos de Graduação em Saúde
                  </StatLabel>
                </Stat>
              </SimpleGrid>
            </Flex>
          </Card>
          <br />
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify="space-between"
            align={{ md: "center" }}
            mb="0px"
          >
            <Flex align="center">
              <Flex bg={bgButtonGroup} borderRadius="10px" p="6px" me="10px">
                <Button
                  variant="no-effects"
                  w={{ sm: "fit-content", xl: "300px" }}
                  h="40px"
                  fontSize="xs"
                  boxShadow={
                    activeTab === "1"
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={activeTab === "1" ? bgActiveButton : "transparent"}
                  onClick={() => setActiveTab("1")}
                >
                  INDICADORES GERAIS
                </Button>
                <Button
                  variant="no-effects"
                  w={{ sm: "fit-content", xl: "300px" }}
                  h="40px"
                  fontSize="xs"
                  boxShadow={
                    activeTab === "2"
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={activeTab === "2" ? bgActiveButton : "transparent"}
                  onClick={() => setActiveTab("2")}
                >
                  SÉRIE HISTÓRICA
                </Button>
                <Button
                  variant="no-effects"
                  w={{ sm: "fit-content", xl: "300px" }}
                  h="40px"
                  fontSize="xs"
                  boxShadow={
                    activeTab === "3"
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={activeTab === "3" ? bgActiveButton : "transparent"}
                  onClick={() => setActiveTab("3")}
                >
                  DISTRIBUIÇÃO ESPACIAL (MAPA)
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Card>
        <Card>
          {activeTab === "1" && (
            <Grid
              templateColumns={{ sm: "1fr", lg: "1fr" }}
              gap="20px"
              my="20px"
            >
              <Card display="flex" justify="left" align="left" minH="0px">
                <Flex direction="column">
                  <Text color="#000" fontSize="md" fontWeight="bold">
                    Indicadores gerais da Educação Superior (Graduação), para os
                    cursos da saúde.
                  </Text>
                  <br />
                  <hr />
                  <br />

                  <Card display="flex" justify="left" align="left" minH="0px">
                    <strong>Filtros</strong>
                    <Flex direction="column" justify="space-between" h="100%">
                      <Grid
                        templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
                        gap="20px"
                      >
                        <Card
                          display="flex"
                          justify="left"
                          align="left"
                          minH="0px"
                        >
                          <strong>Ano:</strong>
                          <Flex align="center" mb="20px">
                            <FormControl>
                              <Select
                                variant="main"
                                placeholder="TOTAL"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  setYear(i.target.value);
                                }}
                                defaultValue={year}
                              >
                                {years.map((year) => (
                                  <option value={year}>{year}</option>
                                ))}
                              </Select>
                            </FormControl>
                          </Flex>
                        </Card>
                        <Card
                          display="flex"
                          justify="left"
                          align="left"
                          minH="0px"
                        >
                          <strong>Categoria Administrativa:</strong>
                          <Flex align="center" mb="20px">
                            <FormControl>
                              <Select
                                variant="main"
                                placeholder="TOTAL"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  setTpCategoriaAdministrativa(i.target.value);
                                }}
                                defaultValue={tpCategoriaAdministrativa}
                              >
                                <option value="publica">PÚBLICA</option>
                                <option value="privada_lucrativa">
                                  PRIVADA COM FINS LUCRATIVOS
                                </option>
                                <option value="privada_nao_lucrativa">
                                  PRIVADA SEM FINS LUCRATIVOS
                                </option>
                              </Select>
                            </FormControl>
                          </Flex>
                        </Card>
                        <Card
                          display="flex"
                          justify="left"
                          align="left"
                          minH="0px"
                        >
                          <strong>Modalidade de ensino:</strong>
                          <Flex align="center" mb="20px">
                            <FormControl>
                              <Select
                                variant="main"
                                placeholder="TOTAL"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  setTpModalidadeEnsino(i.target.value);
                                }}
                                defaultValue={tpModalidadeEnsino}
                              >
                                <option value={1}>Curso presencial</option>
                                <option value={2}>Curso a distância</option>
                              </Select>
                            </FormControl>
                          </Flex>
                        </Card>
                      </Grid>
                    </Flex>
                  </Card>
                  {parseInt(tpModalidadeEnsino) !== 2 && (
                    <Card display="flex" justify="left" align="left" minH="0px">
                      <strong>Filtrar por dimensão geográfica</strong>
                      <Flex direction="column" justify="space-between" h="100%">
                        <Grid
                          templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
                          gap="20px"
                        >
                          <Card
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            <string>Região:</string>
                            <FormControl>
                              <Select
                                variant="main"
                                placeholder="TOTAL"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  _setInepRegiao(i.target.value);
                                }}
                              >
                                {inepRegioes.map((item) => (
                                  <option value={item.cod}>{item.title}</option>
                                ))}
                              </Select>
                            </FormControl>
                          </Card>
                          {inepUfs.length > 0 && (
                            <Card
                              display="flex"
                              justify="left"
                              align="left"
                              minH="0px"
                            >
                              <string>UF:</string>
                              <FormControl>
                                <Select
                                  variant="main"
                                  placeholder="TOTAL"
                                  color="gray.400"
                                  fontSize={16}
                                  onChange={(i) => {
                                    _setInepUf(i.target.value);
                                  }}
                                >
                                  {inepUfs.map((item) => (
                                    <option value={item.cod}>
                                      {item.title}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </Card>
                          )}
                          {inepMunicipios.length > 0 && (
                            <Card
                              display="flex"
                              justify="left"
                              align="left"
                              minH="0px"
                            >
                              Município:
                              <FormControl>
                                <Select
                                  variant="main"
                                  placeholder="TOTAL"
                                  color="gray.400"
                                  fontSize={16}
                                  onChange={(i) => {
                                    setInepMunicipio(i.target.value);
                                  }}
                                >
                                  {inepMunicipios.map((item) => (
                                    <option value={item.cod}>
                                      {item.title}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </Card>
                          )}
                        </Grid>
                      </Flex>
                    </Card>
                  )}

                  <table className="table-filter width-auto">
                    <tr>
                      <th>Curso</th>
                      <th className="cell-background">
                        <Tooltip
                          content={
                            <div style={{ width: "200px" }}>
                              <strong>IES:</strong> Número total de Instituições
                              de Ensino Superior que ofertam os cursos de
                              graduação em saúde ativos no ano.
                            </div>
                          }
                        >
                          IES
                        </Tooltip>
                      </th>
                      <th>
                        <Tooltip
                          content={
                            <div style={{ width: "200px" }}>
                              <strong>Cursos:</strong> Número de cursos de
                              graduação ativos no ano.
                            </div>
                          }
                        >
                          Cursos
                        </Tooltip>
                      </th>
                      <th className="cell-background">
                        <Tooltip
                          content={
                            <div style={{ width: "200px" }}>
                              <strong>Vagas:</strong> Número total de vagas
                              autorizadas pelo MEC para o funcionamento dos
                              cursos no ano.
                            </div>
                          }
                        >
                          Vagas
                        </Tooltip>
                      </th>
                      <th>
                        <Tooltip
                          content={
                            <div style={{ width: "200px" }}>
                              <strong>Inscritos: </strong> Número total de
                              pessoas que se inscreveram nos processos seletivos
                              que habilitam o acesso aos cursos de graduação no
                              ano.
                            </div>
                          }
                        >
                          Inscritos
                        </Tooltip>
                      </th>
                      <th className="cell-background">
                        <Tooltip
                          content={
                            <div style={{ width: "200px" }}>
                              <strong>Razão de inscritos por vaga: </strong>{" "}
                              Expressa a procura pelo curso, quanto maior o
                              resultado, maior é a procura. É dada pela razão
                              entre o número de inscritos no ano e o número de
                              vagas no mesmo ano.
                            </div>
                          }
                        >
                          Razão de inscritos por vagas
                        </Tooltip>
                      </th>
                      <th>
                        <Tooltip
                          content={
                            <div style={{ width: "200px" }}>
                              <strong>Ingressantes: </strong> Número total de
                              pessoas que ingressaram nos cursos de graduação no
                              ano através dos processos seletivos (ENEM,
                              vestibular, obtenção de novo curso, reingresso,
                              transferência).
                            </div>
                          }
                        >
                          Ingressantes
                        </Tooltip>
                      </th>
                      <th className="cell-background">
                        <Tooltip
                          content={
                            <div style={{ width: "200px" }}>
                              <strong>
                                Percentual de preenchimento de vagas:
                              </strong>{" "}
                              Expressa o percentual de vagas autorizadas que
                              foram efetivamente preenchidas através da relação
                              entre o número de ingressos no ano e o número de
                              vagas no mesmo ano.
                            </div>
                          }
                        >
                          % de preenchimento de vagas
                        </Tooltip>
                      </th>
                      <th>
                        <Tooltip
                          content={
                            <div style={{ width: "200px" }}>
                              <strong>Matrículas:</strong> Número total de
                              matrículas ativas em todos os períodos dos cursos
                              de graduação no ano.
                            </div>
                          }
                        >
                          Matrículas
                        </Tooltip>
                      </th>
                      <th className="cell-background">
                        <Tooltip
                          content={
                            <div style={{ width: "200px" }}>
                              <strong>Taxa de abandono:</strong> Indica o
                              percentual de desistência do curso através da
                              relação entre o número de alunos desvinculados +
                              transferidos para outro curso na mesma IES no ano
                              e o número de matrículas no mesmo ano.
                            </div>
                          }
                        >
                          Taxa de abandono
                        </Tooltip>
                      </th>
                      <th>
                        <Tooltip
                          content={
                            <div style={{ width: "200px" }}>
                              <strong>Concluintes:</strong> Número total de
                              pessoas que concluíram os cursos de graduação no
                              ano.
                            </div>
                          }
                        >
                          Concluintes
                        </Tooltip>
                      </th>
                      <th className="cell-background">
                        <Tooltip
                          content={
                            <div style={{ width: "200px" }}>
                              <strong>
                                Razão de sexos de concluintes (Fem/Masc):
                              </strong>{" "}
                              Indica a relação entre o número de concluintes do
                              sexo feminino e o número de concluintes do sexo
                              masculino.
                            </div>
                          }
                        >
                          Razão de Sexos <br /> de Concluintes (fem/masc)
                        </Tooltip>
                      </th>
                    </tr>

                    {inepGeralCollection.length === 0 && (
                      <div>Carregando...</div>
                    )}
                    {inepGeralCollection.map((item, i) => (
                      <tr k={i}>
                        <td>{item.no_cine_rotulo}</td>
                        <td className="cell-background right-align">
                          {formatNumber(item.count_co_ies)}
                        </td>
                        <td className="right-align">
                          {formatNumber(item.qt_curso)}
                        </td>
                        <td className="cell-background right-align">
                          {formatNumber(item.qt_vg_total)}
                        </td>
                        <td className="right-align">
                          {formatNumber(item.qt_inscrito_total)}
                        </td>
                        <td className="cell-background right-align">
                          {item.taxaInscritosPorVaga_format}
                        </td>
                        <td className="right-align">
                          {formatNumber(item.qt_ing)}
                        </td>
                        <td className="cell-background right-align">
                          {item.taxaAproveitamento_format}%
                        </td>
                        <td className="right-align">
                          {formatNumber(item.qt_mat)}
                        </td>
                        <td className="cell-background right-align">
                          {item.taxaAbandono_format}%
                        </td>
                        <td className="right-align">
                          {formatNumber(item.qt_conc)}
                        </td>
                        <td className="cell-background right-align">
                          {item.razaoSexo_format}
                        </td>
                      </tr>
                    ))}
                  </table>
                </Flex>
              </Card>
            </Grid>
          )}

          {activeTab === "2" && <InepTab2 />}
          {activeTab === "3" && <InepTab3 />}

          <Text color="#000" fontSize="md" fontWeight="regular">
            Fonte: EPSM-ObservaRH/NESCON/FM/UFMG a partir dos dados do INEP/MEC
          </Text>
        </Card>
      </Grid>
    </Flex>
  );
};

export default DashboardPage;
