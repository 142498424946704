import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  FormLabel,
  FormControl,
  Select,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import {
  CNES_MERCADO_PROFISSIONAL_OCUPACIONAL,
  GET_UFS,
  GET_REGIOES_SAUDE,
  GET_MUNICIPIOS_BY_CODE,
} from "queries";

import SearchTable1 from "components/Tables/SearchTable1";

const columnsData = {
  sus: [
    {
      Header: <span>Ocupação</span>,
      accessor: "title",
    },
    {
      Header: <span>SUS</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.sus",
      type: "number",
    },
    {
      Header: <span>%</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.sus_percent",
      type: "percent",
    },
    {
      Header: <span>Não SUS</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.nao_sus",
      type: "number",
    },
    {
      Header: <span>%</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.nao_sus_percent",
      type: "percent",
    },
    {
      Header: <span>Total</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.total",
      type: "number",
    },
  ],

  natureza_juridica: [
    {
      Header: <span>Ocupação</span>,
      accessor: "title",
    },
    {
      Header: <span>Público</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.publico",
      type: "number",
    },
    {
      Header: <span>Público Municipal</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.publico_municipal",
      type: "number",
    },

    {
      Header: <span>Público Estadual</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.publico_estadual",
      type: "number",
    },

    {
      Header: <span>Público Federal</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.publico_federal",
      type: "number",
    },
    {
      Header: <span>Público Outros</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.publico_outros",
      type: "number",
    },

    {
      Header: <span>Privado</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.privado",
      type: "number",
    },
    {
      Header: <span>Privado não lucrativo</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.privado_nao_lucrativo",
      type: "number",
    },
    {
      Header: <span>Outros</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.natureza_juridica_outros",
      type: "number",
    },
    {
      Header: <span>Total</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.total",
      type: "number",
    },
  ],
  natureza_juridica_percent: [
    {
      Header: <span>Ocupação</span>,
      accessor: "title",
    },
    {
      Header: <span>Público</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.publico_percent",
      type: "percent",
    },

    {
      Header: <span>Público Municipal</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.publico_municipal_percent",
      type: "percent",
    },
    {
      Header: <span>Público Estadual</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.publico_estadual_percent",
      type: "percent",
    },
    {
      Header: <span>Público Federal</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.publico_federal_percent",
      type: "percent",
    },

    {
      Header: <span>Público Outros</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.publico_outros_percent",
      type: "percent",
    },

    {
      Header: <span>Privado</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.privado_percent",
      type: "percent",
    },
    {
      Header: <span>Privado não lucrativo</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.privado_nao_lucrativo_percent",
      type: "percent",
    },
    {
      Header: <span>Outros</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.natureza_juridica_outros_percent",
      type: "percent",
    },
    {
      Header: <span>Total</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.total",
      type: "number",
    },
  ],

  tipo_do_estabelecimento: [
    {
      Header: <span>Ocupação</span>,
      accessor: "title",
    },
    {
      Header: <span>UBS</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.ubs",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor: "cnesMercadoProfissionalOuOcupaacional.ubs_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Hospital</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.hospital",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor: "cnesMercadoProfissionalOuOcupaacional.hospital_percent",
    //   type: "percent",
    // },
    {
      Header: <span>PA</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.pa",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor: "cnesMercadoProfissionalOuOcupaacional.pa_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Ambulatório</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.ambulatorio",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor: "cnesMercadoProfissionalOuOcupaacional.ambulatorio_percent",
    //   type: "percent",
    // },
    {
      Header: <span>SADT</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.sadt",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor: "cnesMercadoProfissionalOuOcupaacional.sadt_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Outro</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.cod_tipo_agregado_outros",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor:
    //     "cnesMercadoProfissionalOuOcupaacional.cod_tipo_agregado_outros_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Total</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.total",
      type: "number",
    },
  ],

  tipo_do_estabelecimento_percent: [
    {
      Header: <span>Ocupação</span>,
      accessor: "title",
    },
    {
      Header: <span>UBS</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.ubs_percent",
      type: "percent",
    },
    {
      Header: <span>Hospital</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.hospital_percent",
      type: "percent",
    },
    {
      Header: <span>PA</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.pa_percent",
      type: "percent",
    },
    {
      Header: <span>Ambulatório</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.ambulatorio_percent",
      type: "percent",
    },
    {
      Header: <span>SADT</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.sadt_percent",
      type: "percent",
    },
    {
      Header: <span>Outro</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.cod_tipo_agregado_outros_percent",
      type: "percent",
    },
    {
      Header: <span>Total</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.total",
      type: "number",
    },
  ],
  tipo_vinculacao_estabelecimento: [
    {
      Header: <span>Ocupação</span>,
      accessor: "title",
    },
    {
      Header: <span>Contratação Direta com Vínculo Empregatício</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.vinculo_empregaticio",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor:
    //     "cnesMercadoProfissionalOuOcupaacional.vinculo_empregaticio_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Contratação Direta com Vínculo de Autônomo</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.vinculo_autonomo",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor:
    //     "cnesMercadoProfissionalOuOcupaacional.vinculo_autonomo_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Residência, Estágio ou Bolsa</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.residencia_estagio",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor:
    //     "cnesMercadoProfissionalOuOcupaacional.residencia_estagio_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Contratação Intermediada por outro Ente/Entidade</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.intermediada",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor: "cnesMercadoProfissionalOuOcupaacional.intermediada_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Outra</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.cod_vinculacao_outros",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor:
    //     "cnesMercadoProfissionalOuOcupaacional.cod_vinculacao_outros_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Total</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.total",
      type: "number",
    },
  ],
  tipo_vinculacao_estabelecimento_percent: [
    {
      Header: <span>Ocupação</span>,
      accessor: "title",
    },
    {
      Header: <span>Contratação Direta com Vínculo Empregatício</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.vinculo_empregaticio_percent",
      type: "percent",
    },
    {
      Header: <span>Contratação Direta com Vínculo de Autônomo</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.vinculo_autonomo_percent",
      type: "percent",
    },
    {
      Header: <span>Residência, Estágio ou Bolsa</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.residencia_estagio_percent",
      type: "percent",
    },
    {
      Header: <span>Contratação Intermediada por outro Ente/Entidade</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.intermediada_percent",
      type: "percent",
    },
    {
      Header: <span>Outra</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.cod_vinculacao_outros_percent",
      type: "percent",
    },
    {
      Header: <span>Total</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.total",
      type: "number",
    },
  ],
  tipo_vinculo_trabalho: [
    {
      Header: <span>Ocupação</span>,
      accessor: "title",
    },
    {
      Header: <span>Estatutário / RJU</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.estatutario",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor: "cnesMercadoProfissionalOuOcupaacional.estatutario_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Celetista</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.celetista",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor: "cnesMercadoProfissionalOuOcupaacional.celetista_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Temporário</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.temporario",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor: "cnesMercadoProfissionalOuOcupaacional.temporario_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Autônomo</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.autonomo",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor: "cnesMercadoProfissionalOuOcupaacional.autonomo_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Bolsista</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.bolsista",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor: "cnesMercadoProfissionalOuOcupaacional.bolsista_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Outro</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.cod_vinculo_agregado_outro",
      type: "number",
    },
    // {
    //   Header: <span>%</span>,
    //   accessor:
    //     "cnesMercadoProfissionalOuOcupaacional.cod_vinculo_agregado_outro_percent",
    //   type: "percent",
    // },
    {
      Header: <span>Total</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.total",
      type: "number",
    },
  ],
  tipo_vinculo_trabalho_percent: [
    {
      Header: <span>Ocupação</span>,
      accessor: "title",
    },
    {
      Header: <span>Estatutário / RJU</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.estatutario_percent",
      type: "percent",
    },
    {
      Header: <span>Celetista</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.celetista_percent",
      type: "percent",
    },
    {
      Header: <span>Temporário</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.temporario_percent",
      type: "percent",
    },
    {
      Header: <span>Autônomo</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.autonomo_percent",
      type: "percent",
    },
    {
      Header: <span>Bolsista</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.bolsista_percent",
      type: "percent",
    },
    {
      Header: <span>Outro</span>,
      accessor:
        "cnesMercadoProfissionalOuOcupaacional.cod_vinculo_agregado_outro_percent",
      type: "percent",
    },
    {
      Header: <span>Total</span>,
      accessor: "cnesMercadoProfissionalOuOcupaacional.total",
      type: "number",
    },
  ],
};

const allFields = [
  "sus",
  "nao_sus",
  "publico",
  "publico_municipal",
  "publico_estadual",
  "publico_federal",
  "publico_outros",
  "privado",
  "privado_nao_lucrativo",
  "natureza_juridica_outros",

  "ubs",
  "hospital",
  "pa",
  "ambulatorio",
  "sadt",
  "cod_tipo_agregado_outros",

  "vinculo_empregaticio",
  "vinculo_autonomo",
  "residencia_estagio",
  "intermediada",
  "cod_vinculacao_outros",

  "estatutario",
  "celetista",
  "temporario",
  "autonomo",
  "bolsista",
  "cod_vinculo_agregado_outro",
];

const tableColumnsLabels = {
  sus: "Vínculos SUS / Não SUS",
  natureza_juridica: "Natureza Jurídica do Estabelecimento",
  tipo_do_estabelecimento: "Tipo de Estabelecimento",
  tipo_vinculacao_estabelecimento: "Tipo de Vinculação com o Estabelecimento",
  tipo_vinculo_trabalho: "Tipo de Vínculo de Trabalho",
};

const tableContentLabels = {
  num_vinculos: "Número de vínculos",
  num_profissionais: "Número de profissionais",
  num_profissionais_eti: "Número de profissionais em ETI",
};

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.nescon.medicina.ufmg.br",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const years = [
  2024,
  2023,
  2022,
  2021,
  2020,
  2019,
  2018,
  2017,
  2016,
  2015,
  2014,
  2013,
  2012,
  2011,
  2010,
];

const MercadoPofissionalOcupacionalCnes = (props) => {
  const {
    // ufs,
  } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const [
    cnesMercadoProfissionalOuOcupaacional,
    setCnesMercadoProfissionalOuOcupaacional,
  ] = useState([]);
  const [year, setYear] = useState(2021);
  const [ufCode, setUfCode] = useState("0");

  const [selectItemsTable, setSelectItemsTable] = useState(false);
  const [tableItemsAll, setTableItemsAll] = useState([]);
  const [tableItemsChecked, setTableItemsChecked] = useState([]);

  //
  const [isLoading, setIsLoading] = useState(false);
  const [ufs, setUfs] = useState([]);
  const [regioesSaude, setRegioesSaude] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [yearSelected, setYearSelected] = useState("2024");
  const [ufSelected, setUfSelected] = useState("total");
  const [regiaoSaudeSelected, setRegiaoSaudeSelected] = useState("total");
  const [municipioSelected, setMunicipioSelected] = useState("total");

  const [ind_sus, setInd_sus] = useState("total");

  const [tableColumns, setTableColumns] = useState("sus");
  const [tableContent, setTableContent] = useState("num_vinculos");

  const changeUf = async (v) => {
    setUfSelected(v);
    var uf_code = v;

    if (v == "total") {
      setRegioesSaude([]);
      setMunicipios([]);

      setRegiaoSaudeSelected("total");
      setMunicipioSelected("total");
    } else {
      var getRegioesDeSuadeStart = (
        await clientApi.query({
          variables: {
            uf: v,
          },
          query: GET_REGIOES_SAUDE,
        })
      ).data.getRegioesSaude;
      setRegioesSaude(getRegioesDeSuadeStart);

      var getMunicipiosStart = (
        await clientApi.query({
          variables: {
            uf: v,
          },
          query: GET_MUNICIPIOS_BY_CODE,
        })
      ).data.getMunicipios.municipios;
      setMunicipios(getMunicipiosStart);
    }
  };

  useEffect(async () => {
    let r = document.querySelector(":root");
    r.style.setProperty("--chakra-colors-blue-500", "#285d7d");

    setIsLoading(true);

    // GET UFS #@
    var getUfsStart = (
      await clientApi.query({
        query: GET_UFS,
      })
    ).data.getUfs;
    setUfs(getUfsStart);

    let cnesMercadoProfissionalOuOcupaacionalStart = (
      await clientApi.query({
        query: CNES_MERCADO_PROFISSIONAL_OCUPACIONAL,
        variables: {
          type_content: tableContent,
          year: String(yearSelected),
          ind_sus: String(ind_sus),
          uf_code: String(ufSelected),
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,
        },
      })
    ).data.cnesMercadoProfissionalOuOcupaacional
      .cnesMercadoProfissionalOuOcupaacional;

    // Calcula as porcengatens de todos os fields
    cnesMercadoProfissionalOuOcupaacionalStart.map(
      (cnesMercadoProfissionalOuOcupaacionalItem, i) => {
        allFields.map((field) => {
          cnesMercadoProfissionalOuOcupaacionalStart[
            i
          ].cnesMercadoProfissionalOuOcupaacional[`${field}_percent`] =
            (parseInt(
              cnesMercadoProfissionalOuOcupaacionalItem
                .cnesMercadoProfissionalOuOcupaacional[field]
            ) /
              parseInt(
                cnesMercadoProfissionalOuOcupaacionalItem
                  .cnesMercadoProfissionalOuOcupaacional["total"]
              )) *
            100;
        });
      }
    );

    setCnesMercadoProfissionalOuOcupaacional(
      cnesMercadoProfissionalOuOcupaacionalStart
    );
    setTableItemsAll(cnesMercadoProfissionalOuOcupaacionalStart);

    let tableItemsCheckedStart = [];
    cnesMercadoProfissionalOuOcupaacionalStart.map((itemChecked) => {
      tableItemsCheckedStart.push(itemChecked.title);
    });
    setTableItemsChecked(tableItemsCheckedStart);

    setIsLoading(false);
  }, [
    tableContent,
    yearSelected,
    ufSelected,
    regiaoSaudeSelected,
    municipioSelected,
    ind_sus,
  ]); //#@

  const changeCheckboxTableItemsChecked = (i) => {
    var tableItemsCheckedChange = [];
    // if(i.target.checked) {
    //   i.target.value
    // }
    var valueChecked = i.target.value;
    console.log("tableItemsChecked", tableItemsChecked);

    tableItemsAll.map((tableItem) => {
      if (
        tableItem.title !== valueChecked &&
        tableItemsChecked.includes(tableItem.title)
      ) {
        tableItemsCheckedChange.push(tableItem.title);
      } else if (
        tableItem.title === valueChecked &&
        !tableItemsChecked.includes(tableItem.title)
      ) {
        tableItemsCheckedChange.push(tableItem.title);
      }
    });

    var valuesChange = [];
    tableItemsAll.map((tableItem) => {
      if (tableItemsCheckedChange.includes(tableItem.title)) {
        valuesChange.push(tableItem);
      }
    });

    setTableItemsChecked(tableItemsCheckedChange);
    setCnesMercadoProfissionalOuOcupaacional(valuesChange);
  };

  return (
    <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
      <Card display="flex" justify="left" align="left" minH="0px">
        <Flex direction="column" justify="space-between" h="100%">
          <Flex direction="column">
            <Flex direction="column" justify="space-between" h="100%">
              Selecione o conteúdo das colunas:
              <FormControl>
                <Select
                  variant="main"
                  placeholder="- Selecione -"
                  color="gray.400"
                  fontSize={16}
                  onChange={(i) => {
                    setTableColumns(i.target.value);
                  }}
                  defaultValue={tableColumns}
                >
                  <option value="sus">VÍNCULOS SUS / NÃO SUS</option>
                  <option value="natureza_juridica">
                    NATUREZA JURÍDICA DO ESTABELECIMENTO
                  </option>
                  <option value="tipo_do_estabelecimento">
                    TIPO DE ESTABELECIMENTO
                  </option>
                  <option value="tipo_vinculacao_estabelecimento">
                    TIPO DE VINCULAÇÃO COM O ESTABELECIMENTO
                  </option>
                  <option value="tipo_vinculo_trabalho">
                    TIPO DE VÍNCULO DE TRABALHO
                  </option>
                </Select>
              </FormControl>
              Selecione o indicador:
              <FormControl>
                <Select
                  variant="main"
                  placeholder="- Selecione -"
                  color="gray.400"
                  fontSize={16}
                  onChange={(i) => {
                    setTableContent(i.target.value);
                  }}
                  defaultValue={tableContent}
                >
                  <option value="num_vinculos">NÚMERO DE VÍNCULOS</option>
                  <option value="num_profissionais">
                    NÚMERO DE PROFISSIONAIS
                  </option>
                  <option value="num_profissionais_eti">
                    NÚMERO DE PROFISSIONAIS EM ETI
                  </option>
                </Select>
              </FormControl>
              {[
                "tipo_do_estabelecimento",
                "tipo_do_estabelecimento_percent",
              ].includes(tableColumns) && (
                <>
                  Selecione a medida (N e %):
                  <FormControl>
                    <Select
                      variant="main"
                      placeholder="- Selecione -"
                      color="gray.400"
                      fontSize={16}
                      onChange={(i) => {
                        setTableColumns(i.target.value);
                      }}
                    >
                      <option value="tipo_do_estabelecimento" selected={true}>
                        NÚMERO ABSOLUTO
                      </option>
                      <option value="tipo_do_estabelecimento_percent">
                        PORCENTAGEM
                      </option>
                    </Select>
                  </FormControl>
                </>
              )}
              {["natureza_juridica", "natureza_juridica_percent"].includes(
                tableColumns
              ) && (
                <>
                  Selecione a medida (N e %):
                  <FormControl>
                    <Select
                      variant="main"
                      placeholder="- Selecione -"
                      color="gray.400"
                      fontSize={16}
                      onChange={(i) => {
                        setTableColumns(i.target.value);
                      }}
                    >
                      <option value="natureza_juridica" selected={true}>
                        NÚMERO ABSOLUTO
                      </option>
                      <option value="natureza_juridica_percent">
                        PORCENTAGEM
                      </option>
                    </Select>
                  </FormControl>
                </>
              )}
              {[
                "tipo_vinculacao_estabelecimento",
                "tipo_vinculacao_estabelecimento_percent",
              ].includes(tableColumns) && (
                <>
                  Selecione a medida (N e %):
                  <FormControl>
                    <Select
                      variant="main"
                      placeholder="- Selecione -"
                      color="gray.400"
                      fontSize={16}
                      onChange={(i) => {
                        setTableColumns(i.target.value);
                      }}
                    >
                      <option
                        value="tipo_vinculacao_estabelecimento"
                        selected={true}
                      >
                        NÚMERO ABSOLUTO
                      </option>
                      <option value="tipo_vinculacao_estabelecimento_percent">
                        PORCENTAGEM
                      </option>
                    </Select>
                  </FormControl>
                </>
              )}
              {[
                "tipo_vinculo_trabalho",
                "tipo_vinculo_trabalho_percent",
              ].includes(tableColumns) && (
                <>
                  Selecione a medida (N e %):
                  <FormControl>
                    <Select
                      variant="main"
                      placeholder="- Selecione -"
                      color="gray.400"
                      fontSize={16}
                      onChange={(i) => {
                        setTableColumns(i.target.value);
                      }}
                    >
                      <option value="tipo_vinculo_trabalho" selected={true}>
                        NÚMERO ABSOLUTO
                      </option>
                      <option value="tipo_vinculo_trabalho_percent">
                        PORCENTAGEM
                      </option>
                    </Select>
                  </FormControl>
                </>
              )}
              <br />
              <br />
              <strong>Filtrar</strong>
              <Flex direction="column" justify="space-between" h="100%">
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(4, 1fr)" }}
                  gap="20px"
                >
                  <Card display="flex" justify="left" align="left" minH="0px">
                    <strong>Ano:</strong>
                    <Flex align="center" mb="20px">
                      <FormControl>
                        <Select
                          variant="main"
                          placeholder="- Selecione -"
                          color="gray.400"
                          fontSize={16}
                          onChange={(i) => {
                            setYearSelected(i.target.value);
                          }}
                          defaultValue={yearSelected}
                        >
                          {years.map((year) => (
                            <option value={year}>{year}</option>
                          ))}
                        </Select>
                      </FormControl>
                    </Flex>
                  </Card>
                  <Card display="flex" justify="left" align="left" minH="0px">
                    <strong>SUS / NÃO SUS:</strong>
                    <Flex align="center" mb="20px">
                      <FormControl>
                        <Select
                          variant="main"
                          placeholder="- Selecione -"
                          color="gray.400"
                          fontSize={16}
                          onChange={(i) => {
                            setInd_sus(i.target.value);
                          }}
                          defaultValue={ind_sus}
                        >
                          <option value="total">TOTAL</option>
                          <option value="1">SUS</option>
                          <option value="0">NÃO SUS</option>
                        </Select>
                      </FormControl>
                    </Flex>
                  </Card>
                </Grid>
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(4, 1fr)" }}
                  gap="20px"
                >
                  <Card display="flex" justify="left" align="left" minH="0px">
                    <string>UF:</string>
                    <FormControl>
                      <Select
                        variant="main"
                        placeholder="- Selecione -"
                        color="gray.400"
                        fontSize={16}
                        onChange={(i) => {
                          changeUf(i.target.value);
                        }}
                        defaultValue={"total"}
                      >
                        <option value="total">TOTAL</option>
                        {ufs.map((uf) => (
                          <option value={uf.cod}>{uf.title}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Card>
                  {/* {regioesSaude.length > 0 && (
                    <Card display="flex" justify="left" align="left" minH="0px">
                      <string>Macrorregião de Saúde CIR:</string>
                      <FormControl>
                        <Select
                          variant="main"
                          placeholder="- Selecione -"
                          color="gray.400"
                          fontSize={16}
                          onChange={(i) => {
                            setRegiaoSaudeSelected(i.target.value);
                            setMunicipioSelected("total");
                          }}
                          value={regiaoSaudeSelected}
                        >
                          <option value="total">TOTAL</option>
                          {regioesSaude.map((regioaoSaude) => (
                            <option value={regioaoSaude.cod}>
                              {regioaoSaude.title}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Card>
                  )} */}
                  {regioesSaude.length > 0 && (
                    <Card display="flex" justify="left" align="left" minH="0px">
                      <string>Microrregião de Saúde CIR:</string>
                      <FormControl>
                        <Select
                          variant="main"
                          placeholder="- Selecione -"
                          color="gray.400"
                          fontSize={16}
                          onChange={(i) => {
                            setRegiaoSaudeSelected(i.target.value);
                            setMunicipioSelected("total");
                          }}
                          value={regiaoSaudeSelected}
                        >
                          <option value="total">TOTAL</option>
                          {regioesSaude.map((regioaoSaude) => (
                            <option value={regioaoSaude.cod}>
                              {regioaoSaude.title}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Card>
                  )}
                  {regioesSaude.length > 0 && (
                    <Card display="flex" justify="left" align="left" minH="0px">
                      <string>Município:</string>
                      <FormControl>
                        <Select
                          variant="main"
                          placeholder="- Selecione -"
                          color="gray.400"
                          fontSize={16}
                          onChange={(i) => {
                            setRegiaoSaudeSelected("total");
                            setMunicipioSelected(i.target.value);
                          }}
                          value={municipioSelected}
                        >
                          <option value="total">TOTAL</option>
                          {municipios.map((municipio) => (
                            <option value={municipio.cod_ibge}>
                              {municipio.nom_mun_acent}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Card>
                  )}
                  <div className="clear"></div>
                </Grid>
              </Flex>
              <Flex direction="column">
                Profissões e Ocupações:
                {!selectItemsTable && (
                  <Button
                    variant="light"
                    mt="24px"
                    w="220px"
                    h="35px"
                    onClick={() => {
                      setSelectItemsTable(true);
                    }}
                  >
                    Selecionar Profissões e Ocupações
                  </Button>
                )}
                {selectItemsTable && (
                  <Button
                    variant="light"
                    mt="24px"
                    w="150px"
                    h="35px"
                    onClick={() => {
                      setSelectItemsTable(false);
                    }}
                  >
                    Ocultar
                  </Button>
                )}
                {selectItemsTable && (
                  <>
                    {tableItemsChecked.length > 0 && (
                      <Button
                        variant="light"
                        mt="24px"
                        w="100px"
                        h="35px"
                        onClick={() => {
                          setTableItemsChecked([]);
                          setCnesMercadoProfissionalOuOcupaacional([]);
                        }}
                      >
                        Remover Todos
                      </Button>
                    )}
                    {tableItemsChecked.length === 0 && (
                      <Button
                        variant="light"
                        mt="24px"
                        w="100px"
                        h="35px"
                        onClick={() => {
                          setTableItemsChecked(
                            tableItemsAll.map((item) => item.title)
                          );
                          setCnesMercadoProfissionalOuOcupaacional(
                            tableItemsAll
                          );
                        }}
                      >
                        Selecionar todos
                      </Button>
                    )}
                    <div className="form-checkbox">
                      {tableItemsAll.map((tableItem, i) => (
                        <FormControl key={i}>
                          <input
                            type="checkbox"
                            value={tableItem.title}
                            checked={tableItemsChecked.includes(
                              tableItem.title
                            )}
                            onChange={(i) => changeCheckboxTableItemsChecked(i)}
                          />{" "}
                          {tableItem.title}
                        </FormControl>
                      ))}
                    </div>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
          <br />
          <hr />
          <br />
        </Flex>
        <Flex align="flex-end" mb="0px">
          <div>
            <strong>
              {tableContentLabels[tableContent]}, por profissões ou ocupações de
              saúde por {tableColumnsLabels[tableColumns]}.
            </strong>
            <br />
            {isLoading && (
              <Grid
                templateColumns={{ sm: "1fr", lg: "1fr" }}
                gap="20px"
                my="20px"
              >
                <Flex direction="column" justify="space-between" h="100%">
                  <Grid gap="20px">
                    <Flex direction="column">
                      <Text color="#000" fontSize="3xl" fontWeight="bold">
                        Carregando...
                      </Text>
                      <Text color="#000" fontSize="3xl" fontWeight="bold">
                        Isso poderá levar alguns minutos.
                      </Text>
                    </Flex>
                  </Grid>
                </Flex>
              </Grid>
            )}
            {!isLoading && (
              <SearchTable1
                tableData={cnesMercadoProfissionalOuOcupaacional}
                columnsData={columnsData[tableColumns]}
              />
            )}
          </div>

          {/* <table className="table-filter width-auto" >
                    <tr>
                      <th>Ocupação</th>
                      <th>Atividades de<br /> Assistência Social</th>
                      <th>Atividades de<br /> Atenção à Saúde Humana<br /> e Ativ. Veterinárias</th>
                      <th>Educação</th>
                      <th>Adm. Pública, Defesa<br /> e Seguridade Social</th>
                      <th>Outras<br/> Categorias</th>
                      <th>Total da<br /> Economia</th>
                    </tr>
                  {
                    cnesMercadoProfissionalOuOcupaacional.map((cnesMercadoProfissionalOuOcupaacional_item) => (
                      <tr key={cnesMercadoProfissionalOuOcupaacional_item.code}>
                        <td>{cnesMercadoProfissionalOuOcupaacional_item.title}</td>
                        <td align="center"><strong>{cnesMercadoProfissionalOuOcupaacional_item.cnesMercadoProfissionalOuOcupaacional.ibgesub_21}</strong></td>
                        <td align="center"><strong>{cnesMercadoProfissionalOuOcupaacional_item.cnesMercadoProfissionalOuOcupaacional.ibgesub_22}</strong></td>
                        <td align="center"><strong>{cnesMercadoProfissionalOuOcupaacional_item.cnesMercadoProfissionalOuOcupaacional.ibgesub_23}</strong></td>
                        <td align="center"><strong>{cnesMercadoProfissionalOuOcupaacional_item.cnesMercadoProfissionalOuOcupaacional.ibgesub_24}</strong></td>
                        <td align="center"><strong>{cnesMercadoProfissionalOuOcupaacional_item.cnesMercadoProfissionalOuOcupaacional.outros}</strong></td>
                        <td align="center"><strong>{cnesMercadoProfissionalOuOcupaacional_item.cnesMercadoProfissionalOuOcupaacional.total}</strong></td>
                      </tr>
                    ))
                  }
                  </table> */}
        </Flex>
      </Card>
    </Grid>
  );
};

export default MercadoPofissionalOcupacionalCnes;
