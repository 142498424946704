import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  Select,
  FormControl,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import MapInep from "components/Map/MapInep";
import MapInepCircles from "components/Map/MapInepCircles";
import { GET_INEP_GERAL_COLLECTION, GET_INEP_COURSES } from "queries";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { GET_INEP_MAP_CIRCLES } from "queries";

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.nescon.medicina.ufmg.br",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const InepTab3 = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [coCineRotulo, setCoCineRotulo] = useState("TOTAL");
  const [distritc, setDistrict] = useState("");
  const [indicatorColorMap, setIndicatorColorMap] = useState("count_co_ies");
  const textColor = useColorModeValue("gray.700", "white");
  const [inepGeral, setInepGeral] = useState(null);
  const [inepMunicipios, setInepMunicipios] = useState([]);
  const [ufDefault, setUfDefault] = useState("");
  const [municipioCodSelected, setMunicipioCodSelected] = useState("");
  const [latLong, setLatLong] = useState("");
  const [areaSize, setAreaSize] = useState("");
  const [municipios, setMunicipios] = useState([]);
  const [inepCourses, setInepCourses] = useState([]);
  const [inepCourse, setInepCourse] = useState(null);

  const bgButtonGroup = useColorModeValue("gray.200", "navy.700");
  const bgActiveButton = useColorModeValue("#fff", "navy.800");

  useEffect(async () => {
    // LISTA DE CURSOS
    let inepCoursesStart = (
      await clientApi.query({
        query: GET_INEP_COURSES,
      })
    ).data.getInepCourses;
    console.log("inepCoursesStart", inepCoursesStart);
    setInepCourses(inepCoursesStart);
    // let getInepGeralCollectionStart2021 = (
    //   await clientApi.query({
    //     query: GET_INEP_GERAL_COLLECTION,
    //     variables: {
    //       year: 2021,
    //     },
    //   })
    // ).data.getInepGeralCollection.inepGeral;
    // getInepGeralCollectionStart2021.map((item) => {
    //   if (item.co_cine_rotulo === coCineRotulo) {
    //     setInepGeral(item);
    //   }
    // });

    // let municipiosCircle = [
    //   {
    //     year: 2021,
    //     co_regiao: 3,
    //     co_uf: 31,
    //     co_municipio: 3106200,
    //     latLong: [-19.937525, -43.926453],
    //   },
    //   {
    //     year: 2021,
    //     co_regiao: 3,
    //     co_uf: 31,
    //     co_municipio: 3106705,
    //     latLong: [-19.937525, -43.926453],
    //   },
    // ];

    // let _inepMunicipios = [];

    // // BH
    // let getInepGeralCollectionStart2021_municipio = (
    //   await clientApi.query({
    //     query: GET_INEP_GERAL_COLLECTION,
    //     variables: {
    //       year: 2021,
    //       co_regiao: 3,
    //       co_uf: 31,
    //       co_municipio: 3106200,
    //     },
    //   })
    // ).data.getInepGeralCollection.inepGeral;
    // getInepGeralCollectionStart2021_municipio.map((item) => {
    //   if (item.co_cine_rotulo === coCineRotulo) {
    //     _inepMunicipios.push({
    //       latLong: [-19.937525, -43.926453],
    //       data: item,
    //     });
    //   }
    // });

    // // Betim
    // let getInepGeralCollectionStart2021_municipio2 = (
    //   await clientApi.query({
    //     query: GET_INEP_GERAL_COLLECTION,
    //     variables: {
    //       year: 2021,
    //       co_regiao: 3,
    //       co_uf: 31,
    //       co_municipio: 3106705,
    //     },
    //   })
    // ).data.getInepGeralCollection.inepGeral;
    // getInepGeralCollectionStart2021_municipio2.map((item) => {
    //   if (item.co_cine_rotulo === coCineRotulo) {
    //     _inepMunicipios.push({
    //       latLong: [-19.96538, -44.19755],
    //       data: item,
    //     });
    //   }
    // });

    // // Contagem
    // let getInepGeralCollectionStart2021_municipio3 = (
    //   await clientApi.query({
    //     query: GET_INEP_GERAL_COLLECTION,
    //     variables: {
    //       year: 2021,
    //       co_regiao: 3,
    //       co_uf: 31,
    //       co_municipio: 3118601,
    //     },
    //   })
    // ).data.getInepGeralCollection.inepGeral;
    // getInepGeralCollectionStart2021_municipio3.map((item) => {
    //   if (item.co_cine_rotulo === coCineRotulo) {
    //     _inepMunicipios.push({
    //       latLong: [-19.943445, -44.066345],
    //       data: item,
    //     });
    //   }
    // });

    // console.log("_inepMunicipios", _inepMunicipios);

    let getInepMapCircles = (
      await clientApi.query({
        query: GET_INEP_MAP_CIRCLES,
        variables: {
          co_cine_rotulo: coCineRotulo,
        },
      })
    ).data.getInepMapCircles;

    setInepMunicipios(getInepMapCircles);
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "20px" }}>
      <Grid>
        <Card display="flex" justify="center" align="center" minH="0px">
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
            gap="20px"
          >
            <Card display="flex" justify="left" align="left" minH="0px">
              <strong>Curso:</strong>
              <Flex align="center" mb="20px">
                <FormControl>
                  <Select
                    variant="main"
                    placeholder="TOTAL"
                    color="gray.400"
                    fontSize={16}
                    onChange={(i) => {
                      setCoCineRotulo(
                        i.target.value ? i.target.value : "TOTAL"
                      );
                      inepCourses.map((item) => {
                        if (item.co_cine_rotulo === i.target.value) {
                          setInepCourse(item);
                        }
                      });
                    }}
                    defaultValue={"TOTAL"}
                  >
                    {inepCourses.map((item) => (
                      <option value={item.co_cine_rotulo}>
                        {item.no_cine_rotulo}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
            </Card>
            <Card display="flex" justify="left" align="left" minH="0px">
              <strong>Selecione o indicador para o mapa:</strong>
              <Flex align="center" mb="20px">
                <FormControl>
                  <Select
                    variant="main"
                    placeholder="- Selecione -"
                    color="gray.400"
                    fontSize={16}
                    onChange={(i) => {
                      setIndicatorColorMap(i.target.value);
                      // Define o tipo de mapa
                      if (
                        ["qt_vg_total", "qt_curso", "qt_conc"].includes(
                          i.target.value
                        )
                      ) {
                        setActiveTab("2"); // Mapa de círculos
                      } else {
                        setActiveTab("1"); // Mapa comum
                      }
                    }}
                    defaultValue={indicatorColorMap}
                  >
                    <option value="qt_vg_total">Vagas</option>
                    <option value="qt_curso">Cursos</option>
                    <option value="qt_conc">Concluintes</option>
                    <option value="taxaInscritosPorVaga">
                      Razão de inscritos por vaga
                    </option>
                    <option value="taxaAproveitamento">
                      % de preenchimento de vagas
                    </option>
                    <option value="Taxa de abandono">taxaAbandono</option>
                    <option value="razaoSexo">
                      Razão de Sexos de Concluintes (fem/masc)
                    </option>
                    {inepCourse !== null && inepCourse.num_anos > 0 && (
                      <option value="xxxxx">
                        % de não concluintes no tempo previsto
                      </option>
                    )}

                    {/* <option value="xxxxx">Absorção no mercado de trabalho</option> */}
                  </Select>
                </FormControl>
              </Flex>
            </Card>
          </Grid>
        </Card>
      </Grid>

      <Grid templateColumns={{ sm: "1fr", lg: "4fr 1fr" }} gap="20px">
        <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
          {/* <BingMapsReact
            bingMapsKey="At22jzFiq5bsqM0BNF4Mn8H81lNeq5BwbsIxM54Hg6EK7HWtmrB1CvvH13dNGt_h"
            height="500px"
            width="500px"
          /> */}
          {/* <Flex
            direction={{ sm: "column", md: "row" }}
            justify="space-between"
            align={{ md: "center" }}
            mb="0px"
          >
            <Flex align="center">
              <Flex bg={bgButtonGroup} borderRadius="10px" p="6px" me="10px">
                <Button
                  variant="no-effects"
                  w={{ sm: "fit-content", xl: "300px" }}
                  h="40px"
                  fontSize="xs"
                  boxShadow={
                    activeTab === "1"
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={activeTab === "1" ? bgActiveButton : "transparent"}
                  onClick={() => setActiveTab("1")}
                >
                  Visão por Cores
                </Button>
                <Button
                  variant="no-effects"
                  w={{ sm: "fit-content", xl: "300px" }}
                  h="40px"
                  fontSize="xs"
                  boxShadow={
                    activeTab === "2"
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={activeTab === "2" ? bgActiveButton : "transparent"}
                  onClick={() => setActiveTab("2")}
                >
                  Visão por Marcações
                </Button>
              </Flex>
            </Flex>
          </Flex> */}
          {activeTab === "1" && (
            <Card display="flex" justify="center" align="center" minH="0px">
              <MapInep
                coCineRotulo={coCineRotulo}
                indicatorColorMap={indicatorColorMap}
                ufDefault={ufDefault}
                municipioCodSelected={municipioCodSelected}
                latLong={latLong}
                areaSize={areaSize}
              />
            </Card>
          )}
          {activeTab === "2" && (
            <Card display="flex" justify="center" align="center" minH="0px">
              {inepMunicipios.length === 0 && (
                <Text color="#000" fontSize="md" fontWeight="bold">
                  Carregando...
                </Text>
              )}
              {inepMunicipios.length > 0 && (
                <MapInepCircles
                  inepMunicipios={inepMunicipios}
                  coCineRotulo={coCineRotulo}
                  indicatorColorMap={indicatorColorMap}
                  ufDefault={ufDefault}
                  municipioCodSelected={municipioCodSelected}
                  latLong={latLong}
                  areaSize={areaSize}
                />
              )}
            </Card>
          )}
        </Grid>

        <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
          <Flex direction="column" justify="space-between" h="100%">
            <Grid
              templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
              gap="20px"
            >
              {inepGeral !== null && (
                <>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        Dados Brasil
                      </Text>
                    </Flex>
                  </Card>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        {inepGeral.count_co_ies}
                      </Text>
                      <Text
                        color="#333"
                        fontSize="md"
                        fontWeight="bold"
                        title="IES"
                      >
                        IES
                      </Text>
                      <Text color="gray.400" fontSize="xs" fontWeight="bold">
                        Dados INEP 2021
                      </Text>
                    </Flex>
                  </Card>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        {inepGeral.qt_curso}
                      </Text>
                      <Text
                        color="#333"
                        fontSize="md"
                        fontWeight="bold"
                        title="Cursos"
                      >
                        Cursos
                      </Text>
                      <Text color="gray.400" fontSize="xs" fontWeight="bold">
                        Dados INEP 2021
                      </Text>
                    </Flex>
                  </Card>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        {inepGeral.qt_vg_total}
                      </Text>
                      <Text color="#333" fontSize="md" fontWeight="bold">
                        Vagas
                      </Text>
                      <Text color="gray.400" fontSize="xs" fontWeight="bold">
                        Dados INEP 2021
                      </Text>
                    </Flex>
                  </Card>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        {inepGeral.qt_inscrito_total}
                      </Text>
                      <Text color="#333" fontSize="md" fontWeight="bold">
                        Inscritos
                      </Text>
                      <Text color="gray.400" fontSize="xs" fontWeight="bold">
                        Dados INEP 2021
                      </Text>
                    </Flex>
                  </Card>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        {parseFloat(inepGeral.taxaInscritosPorVaga).toFixed(2)}
                      </Text>
                      <Text
                        color="#333"
                        fontSize="md"
                        fontWeight="bold"
                        title="Razão de inscritos por vagas"
                      ></Text>
                      <Text color="gray.400" fontSize="xs" fontWeight="bold">
                        Dados INEP 2021
                      </Text>
                    </Flex>
                  </Card>
                </>
              )}
            </Grid>
          </Flex>
        </Grid>
      </Grid>
    </Flex>
  );
};

export default InepTab3;
