import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import bgWeather from "assets/img/BgMusicCard.png";
import bgWeatherDark from "assets/img/bgMusicCardDark.png";
import smartHome from "assets/img/smart-home.png";
import sunBehindCloud from "assets/img/sun-behind-cloud.png";
import Card from "components/Card/Card";
import BarChart from "components/Charts/BarChart";
import { HSeparator } from "components/Separator/Separator";
import VisxPieChart from "components/VisxPieChart/VisxPieChart";
import CircularSlider from "react-circular-slider-svg";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiWater, BiWifi } from "react-icons/bi";
import { BsFillRecordCircleFill, BsThermometerHigh } from "react-icons/bs";
import { FaPlus, FaSnowflake } from "react-icons/fa";
import { IoBulbOutline, IoEllipsisVerticalSharp } from "react-icons/io5";
import {
  barChartDataDashboardPage,
  barChartOptionsDashboardPage,
} from "variables/charts";
import { rooms } from "variables/general";
import { PulseLoader } from "react-spinners";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { MultiSelect } from "primereact/multiselect";

import { DYNAMIC_REPORT } from "queries";

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.nescon.medicina.ufmg.br",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const filterOptions = {
  raca_cor: [
    { name: "Indígena", code: "01" },
    { name: "Branca", code: "02" },
    { name: "Preta", code: "04" },
    { name: "Amarela", code: "06" },
    { name: "Parda", code: "08" },
  ],
  sexo_trabalhador: [
    { name: "Masculino", code: "01" },
    { name: "Feminino", code: "02" },
  ],
};

const DynamicDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [database, setDatabase] = useState("");
  const [tableRow, setTableRow] = useState("");
  const [tableCol, setTableCol] = useState("");
  const [contentValue, setContentValue] = useState("");
  const [contentCalc, setContentCalc] = useState("");
  // Filters
  const [filters, setFilters] = useState([]);
  const [availableFields, setAvailableFields] = useState(
    Object.keys(filterOptions)
  );

  const applyFilters = () => {
    // Aplicar os filtros
    console.log("Aplicar filtros", filters);
    getReport(database, tableRow, tableCol, contentValue, contentCalc, filters);
  };

  // Adicionar um novo filtro
  const addFilter = () => {
    if (availableFields.length > 0) {
      setFilters([
        ...filters,
        {
          field: availableFields[0], // Seleciona automaticamente o primeiro campo disponível
          value: "",
        },
      ]);
    }
  };

  // Atualizar um filtro existente
  const updateFilter = (index, key, value) => {
    const updatedFilters = filters.map((filter, i) =>
      i === index ? { ...filter, [key]: value } : filter
    );
    setFilters(updatedFilters);

    // Atualizar os campos disponíveis ao selecionar um `field`
    if (key === "field") {
      const selectedFields = updatedFilters.map((filter) => filter.field);
      setAvailableFields(
        Object.keys(filterOptions).filter(
          (field) => !selectedFields.includes(field)
        )
      );
    }
  };

  // Remover um filtro
  const removeFilter = (index) => {
    const removedField = filters[index].field;
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);

    // Re-adicionar o campo removido à lista de disponíveis
    if (removedField) {
      setAvailableFields([...availableFields, removedField]);
    }
  };

  const [reportTable, setReportTable] = useState([]);

  useEffect(async () => {
    let r = document.querySelector(":root");
    r.style.setProperty("--chakra-colors-blue-500", "#285d7d");
  }, []);

  const getReport = async (
    _database,
    _tableRow,
    _tableCol,
    _contentValue,
    _contentCalc,
    _filters
  ) => {
    if (
      _database !== "" &&
      _tableRow !== "" &&
      _tableCol !== "" &&
      _contentValue !== "" &&
      _contentCalc !== ""
    ) {
      setLoading(true);
      let report = (
        await clientApi.query({
          variables: {
            database: _database,
            tableCol: _tableCol,
            tableRow: _tableRow,
            contentValue: _contentValue,
            contentCalc: _contentCalc,
            filters: _filters,
          },
          query: DYNAMIC_REPORT,
        })
      ).data.dynamicReport;

      setReportTable(report.table.rows);

      console.log("report", report);
      setLoading(false);
    }
  };

  const cols = {
    cnes: [
      {
        value: "year",
        label: "Ano",
      },
      {
        value: "uf",
        label: "UF",
      },
    ],
    rais: [
      {
        value: "year",
        label: "Ano",
      },
      {
        value: "uf",
        label: "UF",
      },
      {
        value: "raca_cor",
        label: "Raça / Cor",
      },
    ],
    inep: [],
  };

  const databaseValues = {
    cnes: [
      {
        value: "num_cpf_profissional",
        label: "CPF",
      },
    ],
    rais: [
      {
        value: "idade",
        label: "Idade",
      },
    ],
    inep: [],
  };

  const calcValues = {
    num_cpf_profissional: [
      {
        value: "count",
        label: "Contagem",
      },
    ],
    idade: [
      {
        value: "media",
        label: "Média",
      },
      {
        value: "soma",
        label: "Soma",
      },
      {
        value: "maximo",
        label: "Máximo",
      },
      {
        value: "minimo",
        label: "Mínimo",
      },
    ],
  };

  return (
    <React.Fragment>
      <Grid>
        <Flex direction="column">
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify="space-between"
            align="center"
            w="100%"
            mb="24px"
          ></Flex>
          <Card p="10px">
            <Card p="10px" className="margin-bottom-10">
              <CardHeader p="0px 5px 10px" mb="12px">
                <Text color="#000" fontSize="3xl" fontWeight="bold">
                  Relatórios dinâmicos
                </Text>
              </CardHeader>
              <CardBody px="5px">
                <Card display="flex" justify="left" align="left" minH="0px">
                  <h2>
                    <Text color="#000" fontSize="2xl" fontWeight="bold">
                      Estrutura da tabela
                    </Text>
                  </h2>
                  <Flex direction="column" justify="space-between" h="100%">
                    <Grid
                      templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
                      gap="20px"
                    >
                      <div
                        display="flex"
                        justify="left"
                        align="left"
                        minH="0px"
                      >
                        <strong>Base de dados:</strong>
                        <Flex align="center" mb="20px">
                          <FormControl>
                            <Select
                              variant="main"
                              placeholder="- Selecine a base de dados -"
                              color="gray.400"
                              fontSize={16}
                              onChange={(i) => {
                                setDatabase(i.target.value);
                                getReport(
                                  i.target.value,
                                  tableRow,
                                  tableCol,
                                  contentValue,
                                  contentCalc,
                                  filters
                                );
                              }}
                            >
                              <option value="cnes">CNES</option>
                              <option value="rais">RAIS</option>
                              <option value="inep">INEP</option>
                            </Select>
                          </FormControl>
                        </Flex>
                      </div>
                      {database !== "" && (
                        <>
                          <div
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            <strong>Colunas da tabela:</strong>
                            <Flex align="center" mb="20px">
                              <FormControl>
                                <Select
                                  variant="main"
                                  placeholder="- Selecione as colunas -"
                                  color="gray.400"
                                  fontSize={16}
                                  onChange={(i) => {
                                    setTableCol(i.target.value);
                                    getReport(
                                      database,
                                      tableRow,
                                      i.target.value,
                                      contentValue,
                                      contentCalc,
                                      filters
                                    );
                                  }}
                                >
                                  {cols[database].map((col) => (
                                    <option value={col.value}>
                                      {col.label}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </Flex>
                          </div>
                          <div
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            <strong>Linhas da tabela:</strong>
                            <Flex align="center" mb="20px">
                              <FormControl>
                                <Select
                                  variant="main"
                                  placeholder="- Selecione as linhas -"
                                  color="gray.400"
                                  fontSize={16}
                                  onChange={(i) => {
                                    setTableRow(i.target.value);
                                    getReport(
                                      database,
                                      i.target.value,
                                      tableCol,
                                      contentValue,
                                      contentCalc,
                                      filters
                                    );
                                  }}
                                >
                                  {cols[database].map((col) => (
                                    <option value={col.value}>
                                      {col.label}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </Flex>
                          </div>
                        </>
                      )}
                    </Grid>
                    <Grid
                      templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
                      gap="20px"
                    >
                      <div
                        display="flex"
                        justify="left"
                        align="left"
                        minH="0px"
                      >
                        <strong>Formato:</strong>
                        <Flex align="center" mb="20px">
                          <FormControl>
                            <Select
                              variant="main"
                              placeholder="- Selecine a base de dados -"
                              color="gray.400"
                              fontSize={16}
                              onChange={(i) => {}}
                              value="table"
                            >
                              <option value="table">Tabela</option>
                            </Select>
                          </FormControl>
                        </Flex>
                      </div>
                      {database !== "" && (
                        <>
                          <div
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            <strong>Conteúdo:</strong>
                            <Flex align="center" mb="20px">
                              <FormControl>
                                <Select
                                  variant="main"
                                  placeholder="- Selecione as colunas -"
                                  color="gray.400"
                                  fontSize={16}
                                  onChange={(i) => {
                                    setContentValue(i.target.value);
                                    getReport(
                                      database,
                                      tableRow,
                                      tableCol,
                                      i.target.value,
                                      contentCalc,
                                      filters
                                    );
                                  }}
                                >
                                  {databaseValues[database].map((col) => (
                                    <option value={col.value}>
                                      {col.label}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </Flex>
                          </div>
                          {contentValue !== "" && (
                            <>
                              <div
                                display="flex"
                                justify="left"
                                align="left"
                                minH="0px"
                              >
                                <strong>Cálculo:</strong>
                                <Flex align="center" mb="20px">
                                  <FormControl>
                                    <Select
                                      variant="main"
                                      placeholder="- Selecione as linhas -"
                                      color="gray.400"
                                      fontSize={16}
                                      onChange={(i) => {
                                        setContentCalc(i.target.value);
                                        getReport(
                                          database,
                                          tableRow,
                                          tableCol,
                                          contentValue,
                                          i.target.value,
                                          filters
                                        );
                                      }}
                                    >
                                      {calcValues[contentValue].map((col) => (
                                        <option value={col.value}>
                                          {col.label}
                                        </option>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Flex>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </Flex>
                </Card>

                <Card display="flex" justify="left" align="left" minH="0px">
                  <h2>
                    <Text color="#000" fontSize="2xl" fontWeight="bold">
                      Filtros
                    </Text>
                  </h2>
                  <Flex direction="column" justify="space-between" h="100%">
                    <Grid
                      templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
                      gap="20px"
                    >
                      <div
                        display="flex"
                        justify="left"
                        align="left"
                        minH="0px"
                      >
                        {filters.map((filter, index) => (
                          <div key={index} style={{ marginBottom: "10px" }}>
                            <select
                              value={filter.field}
                              onChange={(e) =>
                                updateFilter(index, "field", e.target.value)
                              }
                            >
                              <option value="" disabled>
                                Selecione um campo
                              </option>
                              {Object.keys(filterOptions).map((k) => (
                                <option key={k} value={k}>
                                  {k}
                                </option>
                              ))}
                            </select>

                            {filter.field && (
                              // <select
                              //   value={filter.value}
                              //   onChange={(e) =>
                              //     updateFilter(index, "value", e.target.value)
                              //   }
                              // >
                              //   <option value="" disabled>
                              //     Selecione um valor
                              //   </option>
                              //   {Object.entries(
                              //     filterOptions[filter.field] || {}
                              //   ).map(([key, value]) => (
                              //     <option key={key} value={key}>
                              //       {value}
                              //     </option>
                              //   ))}
                              // </select>
                              <MultiSelect
                                // value={[filter.value]}
                                onChange={(e) =>
                                  updateFilter(index, "value", e.value)
                                }
                                options={filterOptions[filter.field]}
                                optionLabel="value"
                                placeholder="Selecione um valor"
                                maxSelectedLabels={3}
                                className="w-full md:w-20rem"
                              />
                            )}

                            <button onClick={() => removeFilter(index)}>
                              Remover
                            </button>
                          </div>
                        ))}
                        {availableFields.length > 0 && (
                          <button onClick={addFilter}>Adicionar Filtro</button>
                        )}
                        {filters.length > 0 && (
                          <button onClick={applyFilters}>
                            Aplicar Filtros
                          </button>
                        )}
                      </div>
                    </Grid>
                  </Flex>
                </Card>

                <Card display="flex" justify="left" align="left" minH="0px">
                  {loading && (
                    <>
                      <h2>
                        Carregando{" "}
                        <PulseLoader
                          color="#000"
                          size="7px"
                          speedMultiplier={0.7}
                        />
                      </h2>
                    </>
                  )}
                  {!loading && (
                    <div className="tableContainer">
                      <table className="table-filter width-auto">
                        {reportTable.map((row) => (
                          <tr>
                            {row.cels.map((cel) => (
                              <td>{cel.value}</td>
                            ))}
                          </tr>
                        ))}
                      </table>
                    </div>
                  )}
                </Card>
              </CardBody>
            </Card>
          </Card>
        </Flex>
      </Grid>
    </React.Fragment>
  );
};

export default DynamicDashboard;
